import request from "../commons/request";
import qs from "qs";

export function queryData(data) {
  return request({
    url: "https://llm.itic-sci.com/recommendation/get_recommendation",
    method: "post",
    data,
  });
}

export function postRequestData(data) {
  let tokenType = sessionStorage.getItem("token_type");
  let accessToken = sessionStorage.getItem("access_token");
  return request({
    url: "https://kg-industry.aminersz.cn/chatsearch/chatsearch/feedback",
    method: "post",
    data,
    headers: {
      Authorization: `${tokenType} ${accessToken}`,
    },
  });
}
export function getExportFile(data) {
  let tokenType = sessionStorage.getItem("token_type");
  let accessToken = sessionStorage.getItem("access_token");
  return request({
    url: "https://kg-industry.aminersz.cn/chatsearch/chatsearch/download_history",
    method: "get",
    data,
    headers: {
      Authorization: `${tokenType} ${accessToken}`,
    },
  });
}

const AUTH_PREFIX = "https://kg-industry.aminersz.cn/industry-api-test";

const getManageAuthorization = () => {
  let tokenType = sessionStorage.getItem("back_token_type");
  let accessToken = sessionStorage.getItem("back_access_token");
  return `${tokenType} ${accessToken}`;
};

// 获取管理用户列表
export function getAuthList(data) {
  return request({
    url: `${AUTH_PREFIX}/user/list?${qs.stringify(data)}`,
    method: "get",
    headers: {
      Authorization: getManageAuthorization(),
    },
  });
}

// 新增管理用户
export function getAddAuth(data) {
  return request({
    url: `${AUTH_PREFIX}/user/add`,
    method: "post",
    data,
    headers: {
      Authorization: getManageAuthorization(),
    },
  });
}

// 修改账户信息
export function getModifyAccount(data, userid) {
  return request({
    url: `${AUTH_PREFIX}/user/${userid}/edit`,
    method: "put",
    data,
    headers: {
      Authorization: getManageAuthorization(),
    },
  });
}

// 重置账号密码
export function getResetPassword(data, userid) {
  return request({
    url: `${AUTH_PREFIX}/user/${userid}/reset-password`,
    method: "put",
    data,
    headers: {
      Authorization: getManageAuthorization(),
    },
  });
}

// 登录
export function getAuthLogin(data) {
  return request({
    url: `${AUTH_PREFIX}/login/access-token`,
    method: "post",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// 获取登录信息
export function getLoginInfo() {
  return request({
    url: `${AUTH_PREFIX}/login/getme`,
    method: "get",
    headers: {
      Authorization: getManageAuthorization(),
    },
  });
}

// 查询图谱节点
export function getGraph(data) {
  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/graph?${qs.stringify(data)}`,
    method: "get",
    headers: {
      Authorization: getManageAuthorization(),
    },
  });
}

export function getPersonInterest(params) {
  return request({
    url: `https://apiv2.aminersz.cn/n?a=__PersonInterest.Get___`,
    method: "post",
    data: params,
  });
}

export function getNodeDetail(params) {
  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/entity_detail?${qs.stringify(
      params
    )}`,
    method: "get",
  });
}
export function getOrgInfo(params) {
  return request({
    url: `https://apiv2.aminersz.cn/magic?a=GetOrgInfo__orgapi.get___`,
    method: "post",
    data: params,
  });
}
