import React, { useEffect, useState, Suspense } from "react";
import styles from "./index.less";
import { Radio, Input, message } from "antd";
import loadingSvg from "image/loading.svg";
import CopyPng from "image/copy.png";
import { queryData } from "services/api";
function Overview(props: any) {
  const [radioValue, setRadioValue] = useState("pub");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [resList, setResList] = useState<any>([]);

  const onChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };
  const onInputChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setInputValue(e.target.value);
  };
  const fetchData = async () => {
    setLoading(true);
    setResList([]);
    let res: any = await Promise.all([
      queryData({
        term_id: inputValue,
        term_type: radioValue,
        model_type: "rec-model",
      }),

      queryData({
        term_id: inputValue,
        term_type: radioValue,
        model_type: "gpt-3.5-turbo",
      }),
      queryData({
        term_id: inputValue,
        term_type: radioValue,
        model_type: "chatGLM2-6b",
      }),
    ]);
    setLoading(false);

    if (res.length) {
      let data = [
        {
          model_type: "rec-model",
          str: res[0].recommendation || "暂无推荐",
        },
        {
          model_type: "gpt-3.5-turbo",
          str: res[1].recommendation || "暂无推荐",
        },
        {
          model_type: "chatGLM2-6b",
          str: res[2].recommendation || "暂无推荐",
        },
      ];
      data = data.sort((a, b) => Math.random() - Math.random());
      data.unshift({
        model_type: "input",
        str: res[0].input,
      });
      setResList(data);
    }
    console.log(res);
  };

  const handlePaste = async (e: any) => {
    e.preventDefault();
    if (navigator.clipboard) {
      const text = await navigator.clipboard.readText();
      setInputValue(text);
    } else {
      alert("浏览器不支持 Clipboard API");
    }
  };
  const handleCopy = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("复制成功");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };
  return (
    <div className={styles.content}>
      <p className={styles.title}>推荐增强大模型DEMO</p>
      <div
        className={`${styles.filterPart} ${
          loading && styles.filterPartLoading
        }`}
      >
        <div className={styles.field}>
          <span className={styles.label}>term_type</span>
          <Radio.Group
            onChange={onChange}
            value={radioValue}
            disabled={loading}
          >
            <Radio value={"pub"}>论文</Radio>
            <Radio value={"person"}>专家</Radio>
            <Radio value={"news"}>资讯</Radio>
          </Radio.Group>
        </div>

        <div className={styles.field}>
          <span className={styles.label}>term_id</span>
          <Input
            onChange={onInputChange}
            disabled={loading}
            className={styles.input}
            value={inputValue}
          />
          <span className={styles.paste} onClick={handlePaste}>
            粘贴
          </span>
        </div>

        <div className={styles.btnPart}>
          <span
            className={styles.btnActive}
            onClick={() => {
              fetchData();
            }}
          >
            获取推荐语
          </span>
          <span
            className={styles.btn}
            onClick={() => {
              setInputValue("");
            }}
          >
            清空term_id
          </span>
        </div>
      </div>

      {loading && (
        <p className={styles.loadingCt}>
          <img src={loadingSvg} alt="" className={styles.loading} />
          Loading…
        </p>
      )}

      {resList.length > 0 && (
        <div className={styles.resPart}>
          <p className={styles.idString}>{inputValue}</p>
          {resList.map((item: any) => (
            <div className={`${styles.resItem}`}>
              <p className={styles.toptitle}>
                {item.model_type}
                <img
                  className={styles.copyBtn}
                  src={CopyPng}
                  onClick={() => handleCopy(item.str)}
                />
              </p>
              <p
                className={`${styles.bottomRes} ${
                  item.model_type === "input" && styles.bottomResNormal
                } `}
              >
                {item.model_type === "input" && (
                  // <p style={{ whiteSpace: "pre-line" }}>{item.str}</p>
                  <div>
                    {item.str.split("\n").map((line: string, i: number) => (
                      <p key={i}>{line}</p>
                    ))}
                  </div>
                  // <pre className={styles.preCode}>
                  //   <code>{item.str}</code>
                  // </pre>
                )}
                {item.model_type !== "input" && item.str}

                {item.model_type !== "input" && (
                  <span className={styles.count}>{item.str.length}</span>
                )}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Overview;
