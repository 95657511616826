import React, { useEffect, useState, Suspense } from "react";
import styles from "./App.less";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Overview from "./pages/overview";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Overview />} />
      </Routes>
    </>
  );
}

export default App;
